export default class request {
    static async getDeepLink(url) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "dynamicLinkInfo": {
                "domainUriPrefix": "https://swenio.page.link",
                "link": url,
                "androidInfo": {"androidPackageName": "com.ayodkay.apps.swen"}
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const deeplink = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBx8solBd3NOfF0K3AuSHLbP_j8b8Z1c08"

        return await fetch(deeplink, requestOptions)
    }
}
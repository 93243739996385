import {Outlet} from 'react-router-dom';
import {Divider, experimentalStyled} from '@material-ui/core';
import React from "react";
import ToolBar from "./ToolBar";


const MainLayoutRoot = experimentalStyled('div')(
    ({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        background: "#f4f6f8",
        width: '100%'
    })
);

const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const MainLayout = (props) => {
    const atHome = window.location.pathname === "/"
    return (
        <MainLayoutRoot>
            <MainLayoutWrapper>
                <MainLayoutContainer>
                    <MainLayoutContent>
                        <ToolBar/>
                        <Divider/>
                        {
                            atHome &&
                            <div className={"parallax"}></div>
                        }
                        <Outlet/>
                    </MainLayoutContent>
                </MainLayoutContainer>
            </MainLayoutWrapper>
        </MainLayoutRoot>
    )
}

export default MainLayout;

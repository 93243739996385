import {makeStyles} from "@material-ui/styles"

const useStyles = makeStyles(() => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    '::-webkit-scrollbar': {width: 12},
    '::-webkit-scrollbar-track': {background: '#fAfafa'},
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#e2f2f4',
      borderRadius: 20,
      border: "3px solid white"

    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}))

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;

// --- Post bootstrap -----
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {List, ListItem, Typography} from "@material-ui/core";
import FloatingCard from "./card/FloatingCard";

export default function Privacy() {
    return (
        <React.Fragment>
            <Container>
                <Box mt={7} mb={12}>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        Privacy Policy of oamaru
                    </Typography>

                    <FloatingCard props={
                        <div>
                            <Typography>
                                oamaru operates the https://www.oamaru.com.br/ website, which provides the SERVICE.
                            </Typography>

                            <Typography>
                                This page is used to inform website visitors regarding our policies with the collection,
                                use, and disclosure of Personal Information
                                if anyone decided to use our Service, the oamaru website.
                            </Typography>

                            <Typography>
                                If you choose to use our Service, then you agree to the collection and use of
                                information in relation with this policy. The Personal Information that we collect are
                                used for providing and improving the Service. We will not use or share your
                                information with anyone except as described in this Privacy Policy.
                            </Typography>

                            <Typography>
                                The terms used in this Privacy Policy have the same meanings as in our
                                Terms and Conditions, which is accessible at https://www.oamaru.com.br/,
                                unless otherwise defined in this Privacy Policy.
                            </Typography>

                            <Typography><strong>Information Collection and Use</strong></Typography>
                            <Typography>
                                For a better experience while using our Service, we may require you to provide us with
                                certain personally identifiable information, including but not limited to your name,
                                phone number, and postal address. The information that we collect will be used to
                                contact or identify you.
                            </Typography>

                            <Typography>
                                The app does use third party services that may collect
                                information used to identify you.
                            </Typography>

                            <Typography>
                                Link to privacy policy of third party service providers used
                                by the app
                            </Typography>

                            <List>
                                <ListItem>
                                    <a href="https://www.google.com/policies/privacy/" target="_blank"
                                       rel="noopener noreferrer">Google Play Services</a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank"
                                       rel="noopener noreferrer">AdMob</a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://firebase.google.com/policies/analytics" target="_blank"
                                       rel="noopener noreferrer">Google Analytics for Firebase</a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://firebase.google.com/support/privacy/" target="_blank"
                                       rel="noopener noreferrer">Firebase Crashlytics</a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://www.facebook.com/about/privacy/update/printable" target="_blank"
                                       rel="noopener noreferrer">Facebook</a>
                                </ListItem>
                            </List>

                            <Typography><strong>Log Data</strong></Typography>
                            <Typography>
                                We want to inform you that whenever you visit our Service,
                                we collect information that your browser sends to us that is called Log Data.
                                This Log Data may include information such as your computer’s Internet Protocol
                                ("IP") address, browser version, pages of our Service that you visit, the time and
                                date of your visit, the time spent on those pages, and other statistics.
                            </Typography>

                            <Typography><strong>Cookies</strong></Typography>
                            <Typography>
                                Cookies are files with small amount of data that is commonly used an anonymous unique
                                identifier. These are sent to your browser from the website that you visit and are
                                stored on your computer’s hard drive.
                            </Typography>

                            <Typography>
                                Our website uses these "cookies" to collection information and to improve our Service.
                                You have the option to either accept or refuse these cookies, and know when a cookie is
                                being sent to your computer. If you choose to refuse our cookies, you may not be
                                able to use some portions of our Service.
                            </Typography>

                            <Typography>
                                For more general information on cookies, please read
                                https://www.privacypolicyonline.com/what-are-cookies/

                            </Typography>

                            <Typography><strong>Service Providers</strong></Typography>
                            <Typography>
                                This Service does not use these “cookies” explicitly. However,
                                the app may use third party code and libraries that use
                                “cookies” to collect information and improve their services.
                                You have the option to either accept or refuse these cookies
                                and know when a cookie is being sent to your device. If you
                                choose to refuse our cookies, you may not be able to use some
                                portions of this Service.
                            </Typography>

                            <Typography>
                                We may employ third-party companies and
                                individuals due to the following reasons:
                            </Typography>
                            <List>
                                <ListItem>
                                    To facilitate our Service;
                                </ListItem>
                                <ListItem>
                                    To provide the Service on our behalf;
                                </ListItem>
                                <ListItem>
                                    To perform Service-related services; or
                                </ListItem>
                                <ListItem>
                                    To assist us in analyzing how our Service is used.
                                </ListItem>
                            </List>
                            <Typography>
                                We want to inform users of this Service
                                that these third parties have access to your Personal
                                Information. The reason is to perform the tasks assigned to
                                them on our behalf. However, they are obligated not to
                                disclose or use the information for any other purpose.
                            </Typography>

                            <Typography><strong>Security</strong></Typography>
                            <Typography>
                                We value your trust in providing us your
                                Personal Information, thus we are striving to use commercially
                                acceptable means of protecting it. But remember that no method
                                of transmission over the internet, or method of electronic
                                storage is 100% secure and reliable, and we cannot
                                guarantee its absolute security.
                            </Typography>

                            <Typography><strong>Links to Other Sites</strong></Typography>
                            <Typography>
                                This Service may contain links to other sites. If you click on
                                a third-party link, you will be directed to that site. Note
                                that these external sites are not operated by us.
                                Therefore, we strongly advise you to review the
                                Privacy Policy of these websites. We have
                                no control over and assume no responsibility for the content,
                                privacy policies, or practices of any third-party sites or
                                services.
                            </Typography>

                            <Typography><strong>Children’s Privacy</strong></Typography>
                            <Typography>
                                These Services do not address anyone under the age of 13.
                                We do not knowingly collect personally
                                identifiable information from children under 13 years of age. In the case
                                we discover that a child under 13 has provided
                                us with personal information, we immediately
                                delete this from our servers. If you are a parent or guardian
                                and you are aware that your child has provided us with
                                personal information, please contact us so that
                                we will be able to do necessary actions.
                            </Typography>

                            <Typography><strong>Changes to This Privacy Policy</strong></Typography>
                            <Typography>
                                We may update our Privacy Policy from
                                time to time. Thus, you are advised to review this page
                                periodically for any changes. We will
                                notify you of any changes by posting the new Privacy Policy on
                                this page.
                            </Typography>
                            <Typography>This policy is effective as of 2021-05-30</Typography>


                            <Typography><strong>Contact Us</strong></Typography>
                            <Typography>
                                If you have any questions or suggestions about our
                                Privacy Policy, do not hesitate to contact us at kayode@oamaru.com.
                            </Typography>
                        </div>
                    }/>
                </Box>
            </Container>
        </React.Fragment>
    );
}
import {CardContent, useMediaQuery} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import React from "react"
import {makeStyles} from "@material-ui/styles"


const useStyles = makeStyles(() => ({
    card: {
        borderRadius: 24,
        width: "inherit",
        fontSize: ".875rem",
        border: "0",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
        }

    },
}));
export default function FloatingCard({props}) {

    const classes = useStyles()
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <Card style={{margin: matches && 6}} className={classes.card}>
            <CardContent style={{padding: matches ? 16 : 8}}>
                {props}
            </CardContent>
        </Card>
    )
}
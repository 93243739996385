import React from "react";
import {Button, Grid, useMediaQuery} from "@material-ui/core";
import {decryptMsg} from "../../helper/Crypto/CryptoJS";
import request from "../../helper/Request";
import "@lottiefiles/lottie-player";

export default function Links() {
    const desktop = useMediaQuery('(min-width:600px)');

    const url = unescape(window.location.search.replace("?", ""))
    const [link, setLink] = React.useState("")

    const handleClick = () => {
        window.location = `intent://${link.replace("https", "")}/#Intent;scheme=https;package=com.android.chrome;end`
    }

    let decrypt = decryptMsg(url)
    request.getDeepLink(decrypt)
        .then(response => {
            if (response.status === 200) {
                response.json().then(
                    result => {
                        if (desktop) {
                            window.location.replace(result["shortLink"])
                        } else {
                            setLink(result["shortLink"])
                        }
                    }
                )
            }
        })

    if (window.location.search) {
        return (
            <div>
                <Grid container justifyContent={"center"}>
                    <Grid item>
                        <lottie-player
                            autoplay
                            loop
                            mode="normal"
                            src="https://assets7.lottiefiles.com/packages/lf20_qmfs6c3i.json"
                            style={{width: 320}}
                        >

                        </lottie-player>
                    </Grid>

                    {
                        link && !desktop &&
                        <Button id={"link"} onClick={handleClick.bind()}>
                            abrir no aplicativo
                        </Button>
                    }

                </Grid>
            </div>
        )
    } else {
        window.location.replace("/404")
    }


}
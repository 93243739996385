var CryptoJS = require("crypto-js")
export const decryptMsg = (data) => {
    const master_key = 'abcdefghwhatever';

    // Decode the base64 data so we can separate iv and crypt text.
    let rawData = atob(data);
    // Split by 16 because my IV size
    let iv = rawData.substring(0, 16);
    let crypttext = rawData.substring(16);

    //Parsers
    crypttext = CryptoJS.enc.Latin1.parse(crypttext);
    iv = CryptoJS.enc.Latin1.parse(iv);
    let key = CryptoJS.enc.Utf8.parse(master_key);

    // Decrypt
    var plaintextArray = CryptoJS.AES.decrypt(
        {ciphertext: crypttext},
        key,
        {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}
    );

    // Can be Utf8 too
    return CryptoJS.enc.Latin1.stringify(plaintextArray)
}
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ToolbarMenu from "../components/home/ToolbarMenu";

function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const home = () => {
    window.location.replace("/");
}

export default function ToolBar(props) {
    return (
        <React.Fragment>
            <ElevationScroll {...props}>
                <AppBar>
                    <Toolbar style={{background: '#fff'}}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <Typography variant="h2" noWrap color={"primary"} style={{cursor: "pointer"}}
                                            onClick={home.bind()}>
                                    Oamaru
                                </Typography>
                            </Grid>

                            <Grid item>
                                <ToolbarMenu/>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar/>
        </React.Fragment>
    )
}
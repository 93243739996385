import './App.css';
import {useRoutes} from "react-router-dom";
import {ThemeProvider} from '@material-ui/core';
import routes from "./routes";
import theme from "./theme";
import GlobalStyles from "./layouts/GlobalStyles";

function App() {
    const routing = useRoutes(routes);
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
        </ThemeProvider>
    );
}

export default App;

import {Navigate} from "react-router-dom";
import Links from "./components/link/Links";
import MainLayout from "./layouts/MainLayout";
import Home from "./components/home/Home";
import NotFound from "./layouts/NotFound";
import SwenPrivacy from "./components/apps/swen/components/SwenPrivacy";
import Privacy from "./layouts/Privacy";
import SwenTC from "./components/apps/swen/components/SwenT&C";

const routes = [
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: '/', element: <Home/>},
            {path: 'privacy', element: <Privacy/>},
            {path: 'swen/privacy', element: <SwenPrivacy/>},
            {path: 'swen/terms', element: <SwenTC/>},
            {path: 'links', element: <Links/>},
            {path: 'links/?:searchId', element: <Links/>},

            {path: '404', element: <NotFound/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
];

export default routes;
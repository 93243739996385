// --- Post bootstrap -----
import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {List, ListItem, Typography} from "@material-ui/core";
import FloatingCard from "../../../../layouts/card/FloatingCard";

export default function SwenTC() {
    return (
        <React.Fragment>
            <Container>
                <Box mt={7} mb={12}>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        Terms & Conditions
                    </Typography>

                    <FloatingCard props={
                        <div>
                            <Typography>
                                By downloading or using the app, these terms will automatically apply to you – you
                                should make sure therefore that you read them carefully before using the app.
                                You’re not allowed to copy, or modify the app, any part of the app, or our trademarks
                                in any way. You’re not allowed to attempt to extract the source code of the app, and
                                you also shouldn’t try to translate the app into other languages, or make derivative
                                versions. The app itself, and all the trade marks, copyright, database rights and other
                                intellectual property rights related to it, still belong to Oamaru.
                            </Typography>

                            <Typography>
                                Oamaru is committed to ensuring that the app is as useful and efficient as possible.
                                For that reason, we reserve the right to make changes to the app or to charge for its
                                services, at any time and for any reason. We will never charge you for the app or its
                                services without making it very clear to you exactly what you’re paying for.
                            </Typography>

                            <Typography>
                                The Swen app stores and processes personal data that you have provided to us, in order
                                to provide our Service. It’s your responsibility to keep your phone and access to the
                                app secure. We therefore recommend that you do not jailbreak or root your phone, which
                                is the process of removing software restrictions and limitations imposed by the official
                                operating system of your device. It could make your phone vulnerable to
                                malware/viruses/malicious programs, compromise your phone’s security features and it
                                could mean that the Swen app won’t work properly or at all.
                            </Typography>

                            <Typography>
                                Link to Terms and Conditions of third party service providers used by the app
                            </Typography>

                            <List>
                                <ListItem>
                                    <a href="https://www.google.com/policies/privacy/" target="_blank"
                                       rel="noopener noreferrer">Google Play Services</a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank"
                                       rel="noopener noreferrer">AdMob</a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://firebase.google.com/policies/analytics" target="_blank"
                                       rel="noopener noreferrer">Google Analytics for Firebase</a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://firebase.google.com/support/privacy/" target="_blank"
                                       rel="noopener noreferrer">Firebase Crashlytics</a>
                                </ListItem>
                                <ListItem>
                                    <a href="https://www.facebook.com/about/privacy/update/printable" target="_blank"
                                       rel="noopener noreferrer">Facebook</a>
                                </ListItem>
                            </List>

                            <Typography>
                                We want to inform you that whenever you
                                use our Service, in a case of an error in the app
                                we collect data and information (through third party
                                products) on your phone called Log Data. This Log Data may
                                include information such as your device Internet Protocol
                                (“IP”) address, device name, operating system version, the
                                configuration of the app when utilizing our Service,
                                the time and date of your use of the Service, and other
                                statistics.
                            </Typography>

                            <Typography>
                                You should be aware that there are certain things that Oamaru will not take
                                responsibility for. Certain functions of the app will require the app to have an
                                active internet connection. The connection can be Wi-Fi, or provided by your mobile
                                network provider, but Oamaru cannot take responsibility for the app not working at
                                full functionality if you don’t have access to Wi-Fi, and you don’t have any of your
                                data allowance left.
                            </Typography>

                            <Typography>
                                If you’re using the app outside of an area with Wi-Fi, you should remember that your
                                terms of the agreement with your mobile network provider will still apply.
                            </Typography>

                            <Typography>
                                As a result, you may be charged by your mobile provider for the cost of data for the
                                duration of the connection while accessing the app, or other third party charges.
                            </Typography>

                            <Typography>
                                In using the app, you’re accepting responsibility for any such charges, including
                                roaming data charges if you use the app outside of your home territory
                                (i.e. region or country) without turning off data roaming. If you are not the bill
                                payer for the device on which you’re using the app, please be aware that we assume that
                                you have received permission from the bill payer for using the app.

                                Along the same lines, Oamaru cannot always take responsibility for the way you use the
                                app i.e. You need to make sure that your device stays charged – if it runs out of
                                battery and you can’t turn it on to avail the Service, Oamaru cannot accept
                                responsibility.
                            </Typography>

                            <Typography>
                                With respect to Oamaru’s responsibility for your use of the app,
                                when you’re using the app, it’s important to bear in mind that although we endeavour
                                to ensure that it is updated and correct at all times, we do rely on third parties to
                                provide information to us so that we can make it available to you. Oamaru accepts no
                                liability for any loss, direct or indirect, you experience as a result of relying
                                wholly on this functionality of the app.
                            </Typography>

                            <Typography>
                                At some point, we may wish to update the app. The app is currently available on
                                Android – the requirements for system(and for any additional systems we decide to
                                extend the availability of the app to) may change, and you’ll need to download the
                                updates if you want to keep using the app. Oamaru does not promise that it will always
                                update the app so that it is relevant to you and/or works with the Android version that
                                you have installed on your device. However, you promise to always accept updates to the
                                application when offered to you, We may also wish to stop providing the app, and may
                                terminate use of it at any time without giving notice of termination to you. Unless we
                                tell you otherwise, upon any termination, (a) the rights and licenses granted to you in
                                these terms will end; (b) you must stop using the app, and (if needed) delete it from
                                your device.
                            </Typography>

                            <Typography><strong>Changes to This Terms and Conditions</strong></Typography>
                            <Typography>
                                We may update our Terms and Conditions from time to time. Thus, you are advised to
                                review this page periodically for any changes. We will notify you of any changes by
                                posting the new Terms and Conditions on this page.

                                These terms and conditions are effective as of 2021-05-30
                            </Typography>


                            <Typography><strong>Contact Us</strong></Typography>
                            <Typography>
                                If you have any questions or suggestions about our
                                Privacy Policy, do not hesitate to contact us at kayode@oamaru.com.
                            </Typography>
                        </div>
                    }/>
                </Box>
            </Container>
        </React.Fragment>
    );
}